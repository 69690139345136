import React, { Component } from "react";
import {
  Dropdown,
  Form,
  Input,
  Button,
  Card,
  Dimmer,
  Loader,
} from "semantic-ui-react";

import * as ProviderUi from "../provider";
import * as RequestUi from "./index";

import Provider from "../../../tools/oauth2/provider";
import { JWT } from "../../codeblock";

const empty = function(name: string): IRequestModel {
  return {
    type: RequestUi.RequestType.jwt,
    name,
    scopes: [] as string[],
    client_key: "",
  };
};

type ITokenModel = {
  audience: string;
  audienceName: string;
  token: string;
  token_hint: "access_token" | "id_token";
  sub: string;
  subName: string;
  client_id: string;
  clientName: string;
};

type IRequestModel = RequestUi.IBase & {
  type: RequestUi.RequestType.jwt;
  token?: ITokenModel;
};

type RequestProps = {
  id?: string;
  provider: ProviderUi.IModel;
  request: IRequestModel;
  onSave: (request: IRequestModel) => void;
};

const Request = class extends Component<
  RequestProps,
  { loading: boolean; response: any }
> {
  state = { loading: false, response: null };
  save(request: IRequestModel) {
    this.props.onSave({ ...request });
  }
  render() {
    const provider = new Provider(this.props.provider.metadata);

    return (
      <Card fluid style={{ boxShadow: "none" }}>
        <Card.Content>
          <Dimmer active={this.state.loading}>
            <Loader>Loading</Loader>
          </Dimmer>
          <Form>
            <Form.Group widths="equal">
              <Form.Field>
                <label htmlFor="name">name</label>
                <Input
                  placeholder="name"
                  id="name"
                  onChange={e => {
                    this.save(
                      Object.assign({}, this.props.request, {
                        name: e.target.value,
                      })
                    );
                  }}
                  value={this.props.request.name}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label htmlFor="apis">Token</label>
                <Dropdown
                  id="Token"
                  className="selection"
                  placeholder={
                    this.props.request.token?.token
                      ? `${this.props.request.token.token_hint} for ${
                          this.props.request.token.subName
                        } ${
                          this.props.request.token.subName !==
                          this.props.request.token.clientName
                            ? "on " + this.props.request.token.clientName
                            : ""
                        } ${
                          this.props.request.token.audienceName !==
                          this.props.request.token.clientName
                            ? "with target " +
                              this.props.request.token.audienceName
                            : ""
                        }`
                      : "Token"
                  }
                  value={this.props.request.token?.token}
                  fluid
                >
                  <Dropdown.Menu>
                    {provider.users.map(user => (
                      <React.Fragment key={user.sub + user.client_id}>
                        <Dropdown.Header>
                          {`${ProviderUi.getUserName(
                            this.props.provider,
                            user
                          )} ${
                            user.sub !== user.client_id
                              ? "on " +
                                ProviderUi.getAudienceName(
                                  this.props.provider,
                                  user.client_id
                                )
                              : ""
                          }`}
                        </Dropdown.Header>
                        <Dropdown.Divider />
                        {Object.entries(user.tokens)
                          .map(([audience, tokenSet]) => {
                            const resp = [];
                            if (tokenSet.access_token)
                              resp.push({
                                audience,
                                audienceName: ProviderUi.getAudienceName(
                                  this.props.provider,
                                  audience
                                ),
                                token: tokenSet.access_token,
                                token_hint: "access_token",
                                sub: user.sub,
                                subName: ProviderUi.getUserName(
                                  this.props.provider,
                                  user
                                ),
                                client_id: user.client_id,
                                clientName: ProviderUi.getAudienceName(
                                  this.props.provider,
                                  user.client_id
                                ),
                              });
                            if (tokenSet.id_token)
                              resp.push({
                                audience,
                                audienceName: ProviderUi.getAudienceName(
                                  this.props.provider,
                                  audience
                                ),
                                token: tokenSet.id_token,
                                token_hint: "id_token",
                                sub: user.sub,
                                subName: ProviderUi.getUserName(
                                  this.props.provider,
                                  user
                                ),
                                client_id: user.client_id,
                                clientName: ProviderUi.getAudienceName(
                                  this.props.provider,
                                  user.client_id
                                ),
                              });
                            return resp;
                          })
                          .filter(a => a.length)
                          .flat(1)
                          .map(token => (
                            <Dropdown.Item
                              key={token.token}
                              active={
                                this.props.request.token?.token === token.token
                              }
                              onClick={() => {
                                this.save(
                                  Object.assign({}, this.props.request, {
                                    token,
                                  })
                                );
                              }}
                            >
                              {`${token.token_hint} ${
                                token.audienceName !== token.clientName
                                  ? "with target " + token.audienceName
                                  : ""
                              }`}
                            </Dropdown.Item>
                          ))}
                      </React.Fragment>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Field>
            </Form.Group>
          </Form>
          <Button
            fluid
            color="green"
            onClick={async () => {
              this.setState({ response: this.props.request.token?.token });
            }}
          >
            Run
          </Button>

          {this.state.response ? <JWT children={this.state.response}></JWT> : ""}
        </Card.Content>
      </Card>
    );
  }
};

export { Request, IRequestModel, empty };
