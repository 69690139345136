import React, { Component } from "react";
import { Card, Container, Grid, Tab } from "semantic-ui-react";

import * as ProviderUi from "./provider";
import * as RequestUi from "./request";
import * as AuthorizationtUi from "./request/authorization";
import * as TokenUi from "./token";

import { Request } from "../../tools/oauth2/utils";
import { Response } from "../../tools/oauth2/request";
import { uuidv4 } from "../../tools/oauth2/utils";

type IStorage = {
  providers?: {
    list: {
      [key: string]: ProviderUi.IModel;
    };
    active?: string;
  };
};

const read = (): IStorage => {
  let resp;
  try {
    resp = JSON.parse(localStorage.getItem("workspace"));
  } catch (ignored) {}
  return resp || { providers: { list: {}, active: null } };
};

const save = (store: IStorage): void => {
  localStorage.setItem("workspace", JSON.stringify(store));
};

type PlaygroundState = IStorage & {
  request?: Request;
};

enum PlaygroundSection {
  request = "request",
  token = "token",
  api = "api",
}

type PlaygroundProps = {
  section: PlaygroundSection;
};

const Playground = class Playground extends Component<
  PlaygroundProps,
  PlaygroundState
> {
  state: PlaygroundState = read();

  async componentDidMount() {
    const currentParams = new URL(window.location.href).searchParams;
    if (currentParams.has("error"))
      return console.log({
        error: currentParams.get("error"),
        error_description: currentParams.get("error_description"),
      });
    if (currentParams.has("code")) {
      const response = await Response.fromUrl();
      const copy = { ...this.state };
      const provider = Object.entries(copy.providers.list).find(
        ([_, provider]) =>
          provider.metadata.issuer === response.provider.metadata.issuer
      );
      if (provider) {
        const [key, _] = provider;
        (copy.providers.list[key].requests.list[
          response.request.state.key
        ] as AuthorizationtUi.IRequestModel).response = response;
        this.saveChange(copy);
      }
    }
    if (
      !currentParams.has("code") &&
      !currentParams.has("token") &&
      !currentParams.has("id_token")
    ) {
    }
  }

  async saveChange(store: IStorage) {
    save(store);
    this.setState(store);
  }

  render() {
    if (!this.state) return <div></div>;
    const panes = [
      {
        menuItem: "Authentication",
        active: this.props.section === PlaygroundSection.request,
        render: () => (
          <Tab.Pane>
            <Grid stackable padded="horizontally">
              {this.state.providers.active ? (
                <RequestUi.List
                  provider={
                    this.state.providers.list[this.state.providers.active]
                  }
                  onSave={env => {
                    const providers = {
                      list: Object.assign(
                        {},
                        { ...this.state.providers.list },
                        { [this.state.providers.active]: env }
                      ),
                      active: this.state.providers.active,
                    };
                    const store = Object.assign(
                      {},
                      {
                        providers,
                      }
                    );
                    this.saveChange(store);
                  }}
                />
              ) : (
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Card fluid header="Choose a Provider"></Card>
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Token Management",
        active: this.props.section === PlaygroundSection.token,
        render: () => (
          <Tab.Pane>
            <Grid stackable padded="horizontally">
              {this.state.providers.active ? (
                <TokenUi.List provider={this.state.providers.list[this.state.providers.active]} onDelete={() => this.saveChange({...this.state})}/>
              ) : (
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Card fluid header="Choose a Provider"></Card>
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <>
        <Container fluid>
          <Grid stackable padded>
            <Grid.Row>
              <Grid.Column>
                <ProviderUi.Header
                  providers={this.state.providers.list}
                  active={this.state.providers.active}
                  onAdd={env => {
                    const providers = {
                      list: Object.assign(
                        {},
                        { ...this.state.providers.list },
                        { [uuidv4()]: env }
                      ),
                      active: this.state.providers.active,
                    };
                    const store = Object.assign({}, this.state, { providers });
                    this.saveChange(store);
                    return true;
                  }}
                  onSave={(key, env) => {
                    const providers = {
                      list: Object.assign(
                        {},
                        { ...this.state.providers.list },
                        { [key]: env }
                      ),
                      active: this.state.providers.active,
                    };
                    const store = Object.assign({}, this.state, { providers });
                    this.saveChange(store);
                    return true;
                  }}
                  onDelete={key => {
                    const list = { ...this.state.providers.list };
                    delete list[key];
                    const providers = { list: Object.assign({}, list), active: this.state.providers.active === key ? null : this.state.providers.active };
                    const store = Object.assign({}, this.state, { providers });
                    this.saveChange(store);
                  }}
                  onActivate={key => {
                    const providers = {
                      list: Object.assign({}, { ...this.state.providers.list }),
                      active: key,
                    };
                    const store = Object.assign({}, this.state, { providers });
                    this.saveChange(store);
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        <Container fluid style={{ padding: "0 1em" }}>
          <Tab
            panes={panes}
            grid={{ style: { paddingLeft: 0, paddingRight: 0 } }}
          />
        </Container>
      </>
    );
  }
};

export { Playground, PlaygroundSection };

class Ui extends Component {
  render() {
    return <Playground section={PlaygroundSection.request} />;
  }
}

export default Ui;
