import React, { Component } from "react";
import { Button, Card, Modal, Grid, Menu } from "semantic-ui-react";
import * as Semantic from "semantic-ui-react";

import * as ProviderUi from "../provider";
import * as AuthorizationUi from "./authorization";
import * as ClientCredentialUi from "./client_credentials";
import * as IntrospectionUi from "./introspection";
import * as UserinfoUi from "./userinfo";
import * as RefreshTokenUi from "./refresh_token";
import * as JwtUi from "./jwt";
import * as EndSessionUi from "./endSession";
import * as ClientManagementUi from "./client_management";
import { Playground, PlaygroundSection } from "../index";

import { uuidv4 } from "../../../tools/oauth2/utils";
enum RequestType {
  authorization = "authorization",
  client_credentials = "client_credentials",
  introspection = "introspection",
  userinfo = "userinfo",
  jwt = "jwt",
  refresh_token = "refresh_token",
  client_management = "client_management",
  end_session = "end_session",
}

const empty = function empty(type: RequestType): IModel {
  if (type === RequestType.authorization)
    return AuthorizationUi.empty(RequestType.authorization);
  if (type === RequestType.client_credentials)
    return ClientCredentialUi.empty(RequestType.client_credentials);
  if (type === RequestType.introspection)
    return IntrospectionUi.empty(RequestType.introspection);
  if (type === RequestType.userinfo)
    return UserinfoUi.empty(RequestType.userinfo);
  if (type === RequestType.refresh_token)
    return RefreshTokenUi.empty(RequestType.refresh_token);
  if (type === RequestType.jwt) return JwtUi.empty(RequestType.jwt);
  if (type === RequestType.end_session) return EndSessionUi.empty(RequestType.end_session);
  if (type === RequestType.client_management) return ClientManagementUi.empty(RequestType.client_management);
};

type IBase = {
  name: string;
  type: RequestType;
  client_key: string;
  scopes: string[];
};

type IModel =
  | AuthorizationUi.IRequestModel
  | ClientCredentialUi.IRequestModel
  | IntrospectionUi.IRequestModel
  | UserinfoUi.IRequestModel
  | JwtUi.IRequestModel
  | RefreshTokenUi.IRequestModel
  | ClientManagementUi.IRequestModel
  | EndSessionUi.IRequestModel;

type CreateProps = {
  onCreate: ({ type }: { type: RequestType }) => void;
};
type CreateState = {
  open: boolean;
  type?: RequestType;
};
const Create = class Create extends Component<CreateProps, CreateState> {
  state = { open: false, name: "", type: null };

  render() {
    const { children } = this.props;
    const { open } = this.state;
    return (
      <Modal
        onClose={() => this.setState({ open: false })}
        onOpen={() => this.setState({ open: true })}
        open={open}
        trigger={children}
        centered={false}
      >
        <Modal.Header>Request</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Grid>
              <Grid.Row>
                {Object.keys(RequestType).map((type: RequestType) => (
                  <Grid.Column key={type} tablet="5" mobile="16" widescreen="5" largeScreen="5" computer="5" style={{margin: '10px 0'}}>
                    <Card
                      header={type}
                      fluid
                      link
                      onClick={() => {
                        this.setState({ open: false });
                        this.props.onCreate({ type });
                      }}
                    ></Card>
                  </Grid.Column>
                ))}
              </Grid.Row>
            </Grid>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
};

const Item = class Item extends Component<
  {
    id?: string;
    request: IModel;
    provider: ProviderUi.IModel;
    onSave: (req: IModel) => void;
  },
  {}
> {
  save(request: IModel) {
    this.props.onSave({ ...request });
  }
  render() {
    return this.props.request?.type === RequestType.authorization ? (
      <AuthorizationUi.Request
        id={this.props.id}
        provider={this.props.provider}
        request={this.props.request}
        onSave={this.save.bind(this)}
      />
    ) : this.props.request?.type === RequestType.client_credentials ? (
      <ClientCredentialUi.Request
        id={this.props.id}
        provider={this.props.provider}
        request={this.props.request}
        onSave={this.save.bind(this)}
      />
    ) : this.props.request?.type === RequestType.introspection ? (
      <IntrospectionUi.Request
        id={this.props.id}
        provider={this.props.provider}
        request={this.props.request}
        onSave={this.save.bind(this)}
      />
    ) : this.props.request?.type === RequestType.userinfo ? (
      <UserinfoUi.Request
        id={this.props.id}
        provider={this.props.provider}
        request={this.props.request}
        onSave={this.save.bind(this)}
      />
    ) : this.props.request?.type === RequestType.jwt ? (
      <JwtUi.Request
        id={this.props.id}
        provider={this.props.provider}
        request={this.props.request}
        onSave={this.save.bind(this)}
      />
    )  : this.props.request?.type === RequestType.refresh_token ? (
      <RefreshTokenUi.Request
        id={this.props.id}
        provider={this.props.provider}
        request={this.props.request}
        onSave={this.save.bind(this)}
      />
    ) : this.props.request?.type === RequestType.end_session ? (
      <EndSessionUi.Request
        id={this.props.id}
        provider={this.props.provider}
        request={this.props.request}
        onSave={this.save.bind(this)}
      />
    ) : this.props.request?.type === RequestType.client_management ? (
      <ClientManagementUi.Request
        id={this.props.id}
        provider={this.props.provider}
        request={this.props.request}
        onSave={this.save.bind(this)}
      />
    ) : (
      ""
    );
  }
};

const List = class extends Component<
  {
    provider: ProviderUi.IModel;
    onSave: (env: ProviderUi.IModel) => void;
  },
  {}
> {
  activate(id: string) {
    let requests = {
      active: id,
      list: { ...this.props.provider.requests.list },
    };

    const env = Object.assign({ ...this.props.provider }, { requests });
    this.props.onSave(env);
  }

  save(id: string, request: IModel) {
    id = id || uuidv4();

    let requests = this.props.provider.requests
      ? {
          active: this.props.provider.requests.active,
          list: { ...this.props.provider.requests.list },
        }
      : { list: {} };
    requests.list[id || uuidv4()] = request;
    requests.active = id;

    const env = Object.assign({ ...this.props.provider }, { requests });
    this.props.onSave(env);
  }

  delete(id: string) {
    let requests = this.props.provider.requests
      ? {
          active: this.props.provider.requests.active,
          list: { ...this.props.provider.requests.list },
        }
      : { list: {} };

    delete requests.list[id];

    const env = Object.assign({ ...this.props.provider }, { requests });
    this.props.onSave(env);
  }

  render() {
    return (
      <Grid.Row>
        <Grid.Column width={4} style={{ padding: 0 }}>
          <Menu vertical fluid pointing>
            <Menu.Item>
              <Grid>
                <Grid.Row>
                  <Grid.Column verticalAlign="middle" style={{ flexGrow: 1 }}>
                    <Semantic.Header as="h4">Requests</Semantic.Header>
                  </Grid.Column>
                  <Grid.Column style={{ width: "50px" }}>
                    <Create
                      onCreate={({ type }) => {
                        this.save(null, empty(type));
                      }}
                    >
                      <Button
                        icon="add"
                        floated="right"
                        circular
                        color="blue"
                      />
                    </Create>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Menu.Item>
            {!Object.keys(this.props.provider.requests?.list).length ? (
              <Menu.Item>Nothing to show</Menu.Item>
            ) : (
              Object.entries(this.props.provider.requests.list).map(
                ([id, request]) => {
                  return (
                    <Menu.Item
                      key={id}
                      active={this.props.provider.requests.active === id}
                      onClick={() => this.activate(id)}
                    >
                      <Grid>
                        <Grid.Row>
                          <Grid.Column
                            verticalAlign="middle"
                            style={{ flexGrow: 1 }}
                          >
                            <Semantic.Label horizontal>
                              {request.type}
                            </Semantic.Label>
                            <span>{request.name}</span>
                          </Grid.Column>
                          <Grid.Column style={{ width: "50px" }}>
                            <Button.Group size="small" floated="right">
                              <Button
                                negative
                                icon="trash alternate"
                                onClick={e => {
                                  e.stopPropagation();
                                  this.delete(id);
                                }}
                              />
                            </Button.Group>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Menu.Item>
                  );
                }
              )
            )}
          </Menu>
        </Grid.Column>

        <Grid.Column width={12} style={{ padding: 0 }}>
          {this.props.provider.requests.active &&
          this.props.provider.requests.list[
            this.props.provider.requests.active
          ] ? (
            <Item
              id={this.props.provider.requests.active}
              request={
                this.props.provider.requests.list[
                  this.props.provider.requests.active
                ]
              }
              onSave={req =>
                this.save(this.props.provider.requests.active, req)
              }
              provider={this.props.provider}
            ></Item>
          ) : (
            <Card fluid>
              <Card.Content>Choose a Request to get started</Card.Content>
            </Card>
          )}
        </Grid.Column>
      </Grid.Row>
    );
  }
};

class Ui extends Component {
  render() {
    return <Playground section={PlaygroundSection.request} />;
  }
}

export default Ui;

export { HttpRequest } from "./http";
// export { Request as AuthorizationCodeRequest } from "./authorization_code";

export { IModel, List, Item, IBase, RequestType };
