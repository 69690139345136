import React, { Component } from "react";
import {
  Button,
  Container,
  Dimmer,
  Form,
  Loader,
  Dropdown,
} from "semantic-ui-react";

import * as ProviderUi from "../provider";
import * as AuthorizationUi from "./authorization";
import * as HttpRequestUi from "./http";

import * as Authorization from "../../../tools/oauth2/request";
import { ApiType } from "../provider/api";
import CodeBlock from "../../codeblock";

type RequestProps = {
  id?: string;
  provider: ProviderUi.IModel;
  request: AuthorizationUi.IRequestModel;
  onSave: (request: AuthorizationUi.IRequestModel) => void;
};

const Request = class extends Component<
  RequestProps,
  {
    loading: boolean;
    api: string;
    response: any;
  }
> {
  state = { loading: false, api: "", response: null };
  save(request: AuthorizationUi.IRequestModel) {
    this.props.onSave({ ...request });
  }
  render() {
    const client = this.props.provider.clients?.list[
      this.props.request.client_key
    ];
    const request = new Authorization.Response({
      metadata: this.props.provider.metadata,
      client,
      request: this.props.request.response.request,
      code: this.props.request.response.code,
    });

    const params = {} as any;
    const api = this.state.api && this.props.provider.apis.list[this.state.api];
    if (api && api.type === ApiType.audience) params.audience = api.ref;
    else if (api && api.type === ApiType.resource) params.resource = api.ref;

    return (
      <Container fluid style={{ position: "relative" }}>
        <Dimmer active={this.state.loading}>
          <Loader inline>Loading</Loader>
        </Dimmer>
        <HttpRequestUi.HttpRequest
          request={request.debug(params)}
          title="Authorization Code Request"
        />
        <Form>
          <Form.Group widths="equal">
            {Object.keys(this.props.provider.apis.list).length ?
              <Form.Field>
                <label htmlFor="apis">API</label>
                <Dropdown
                  id="apis"
                  options={Object.entries(this.props.provider.apis.list).map(
                    ([key, api]) => ({
                      key,
                      value: key,
                      text: ProviderUi.getAudienceName(
                        this.props.provider,
                        api.ref
                      ),
                    })
                  )}
                  placeholder="apis"
                  selection
                  fluid
                  value={this.state.api}
                  onChange={async (_, { value }: { value: string }) => {
                    this.setState({ api: value });
                  }}
                />
              </Form.Field>
            : ""}
          </Form.Group>
        </Form>
        <Button
          fluid
          color="green"
          onClick={async () => {
            this.setState({ loading: true });
            try {
              const response = await request.execute(params);
              this.setState({ response, loading: false });
            } catch (e) {
              this.setState({ response: {error: e.name}, loading: false});
            }
            // delete this.props.request.response;
            // this.save(this.props.request);
          }}
        >
          Finish Login
        </Button>
        {this.state.response ? (
          <>
            <CodeBlock
              code={JSON.stringify(this.state.response, null, 2)}
              language="json"
            />
            <Button
              fluid
              color="red"
              onClick={async () => {
                delete this.props.request.response;
                this.save(this.props.request);
              }}
            >
              Clear
            </Button>
          </>
        ) : (
          ""
        )}
      </Container>
    );
  }
};

export { Request };
