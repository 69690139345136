import React, { Component } from "react";
import {
  Button,
  Card,
  Grid,
  Modal,
  Form,
  Input,
  Tab,
  Dropdown,
  Menu,
} from "semantic-ui-react";
import * as Semantic from "semantic-ui-react";
import CodeBlock from "../../codeblock";

import * as Client from "./client";
import * as Api from "./api";
import * as Request from "../request";
import * as Provider from "../../../tools/oauth2/provider";

const emptyProvider = function(): IModel {
  return {
    name: "",
    metadata: emptyMetadata(),
    clients: {
      list: {},
      active: null,
    },
    requests: {
      list: {},
      active: null,
    },
    apis: {
      list: {},
    },
  };
};

const emptyMetadata = function emptyMetadata(): Provider.IModel {
  return {
    issuer: "https://",
    authorization_endpoint: "",
    token_endpoint: "",
    userinfo_endpoint: "",
    revocation_endpoint: "",
    introspection_endpoint: "",
    end_session_endpoint: "",
    jwks_uri: "",
    registration_endpoint: "",
    frontchannel_logout_supported: false,
    frontchannel_logout_session_supported: false,
    grant_types_supported: [],
    response_types_supported: [],
    subject_types_supported: [],
    id_token_signing_alg_values_supported: [],
    scopes_supported: [],
    token_endpoint_auth_methods_supported: [],
    revocation_endpoint_auth_methods_supported: [],
    introspection_endpoint_auth_methods_supported: [],
    response_modes_supported: [],
    code_challenge_methods_supported: [],
    claims_supported: [],
    acr_values_supported: [],
  };
};

type IModel = {
  name: string;
  metadata: Provider.IModel;
  clients?: {
    list: { [key: string]: Client.IModel };
    active: string;
  };
  requests?: {
    list: { [key: string]: Request.IModel };
    active: string;
  };
  apis?: {
    list: {
      [key: string]: Api.IModel;
    };
  };
};

const ItemEndpoints = class ItemEndpoints extends Component<{
  metadata: Provider.IModel;
  onChange: (metadata: Provider.IModel) => void;
}> {
  render() {
    return (
      <>
        <Form.Group widths="equal">
          <Form.Field>
            <label htmlFor="authorization_endpoint">
              authorization_endpoint
            </label>
            <Input
              placeholder="authorization_endpoint"
              id="authorization_endpoint"
              onChange={e =>
                this.props.onChange(
                  Object.assign({}, this.props.metadata, {
                    authorization_endpoint: e.target.value,
                  })
                )
              }
              value={this.props.metadata.authorization_endpoint}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label htmlFor="userinfo_endpoint">userinfo_endpoint</label>
            <Input
              placeholder="userinfo_endpoint"
              id="userinfo_endpoint"
              onChange={e =>
                this.props.onChange(
                  Object.assign({}, this.props.metadata, {
                    userinfo_endpoint: e.target.value,
                  })
                )
              }
              value={this.props.metadata.userinfo_endpoint}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label htmlFor="token_endpoint">token_endpoint</label>
            <Input
              placeholder="token_endpoint"
              id="token_endpoint"
              onChange={e =>
                this.props.onChange(
                  Object.assign({}, this.props.metadata, {
                    token_endpoint: e.target.value,
                  })
                )
              }
              value={this.props.metadata.token_endpoint}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label htmlFor="introspection_endpoint">
              introspection_endpoint
            </label>
            <Input
              placeholder="introspection_endpoint"
              id="introspection_endpoint"
              onChange={e =>
                this.props.onChange(
                  Object.assign({}, this.props.metadata, {
                    introspection_endpoint: e.target.value,
                  })
                )
              }
              value={this.props.metadata.introspection_endpoint}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label htmlFor="revocation_endpoint">revocation_endpoint</label>
            <Input
              placeholder="revocation_endpoint"
              id="revocation_endpoint"
              onChange={e =>
                this.props.onChange(
                  Object.assign({}, this.props.metadata, {
                    revocation_endpoint: e.target.value,
                  })
                )
              }
              value={this.props.metadata.revocation_endpoint}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label htmlFor="end_session_endpoint">end_session_endpoint</label>
            <Input
              placeholder="end_session_endpoint"
              id="end_session_endpoint"
              onChange={e =>
                this.props.onChange(
                  Object.assign({}, this.props.metadata, {
                    end_session_endpoint: e.target.value,
                  })
                )
              }
              value={this.props.metadata.end_session_endpoint}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label htmlFor="registration_endpoint">registration_endpoint</label>
            <Input
              placeholder="registration_endpoint"
              id="registration_endpoint"
              onChange={e =>
                this.props.onChange(
                  Object.assign({}, this.props.metadata, {
                    registration_endpoint: e.target.value,
                  })
                )
              }
              value={this.props.metadata.registration_endpoint}
            />
          </Form.Field>
        </Form.Group>
      </>
    );
  }
};

const ItemFeatures = class ItemFeatures extends Component<{
  metadata: Provider.IModel;
  onChange: (metadata: Provider.IModel) => void;
}> {
  render() {
    return (
      <>
        <Form.Group widths="equal">
          <Form.Field>
            <label htmlFor="grant_types_supported">grant_types_supported</label>
            <Dropdown
              options={Array.from(
                this.props.metadata.grant_types_supported
              ).map(i => ({ key: i, value: i, text: i }))}
              placeholder="grant_types_supported"
              search
              multiple
              selection
              fluid
              allowAdditions
              value={this.props.metadata.grant_types_supported || []}
              onAddItem={(_, { value }) => {
                this.props.onChange(
                  Object.assign({}, this.props.metadata, {
                    grant_types_supported: Array.from(
                      new Set([
                        ...this.props.metadata.grant_types_supported,
                        value as Provider.GrantType,
                      ])
                    ),
                  })
                );
              }}
              onChange={(_, data) => {
                this.props.onChange(
                  Object.assign({}, this.props.metadata, {
                    grant_types_supported: [
                      ...((data.value as Provider.GrantType[]) || []),
                    ],
                  })
                );
              }}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label htmlFor="response_types_supported">
              response_types_supported
            </label>
            <Dropdown
              options={Array.from(
                this.props.metadata.response_types_supported
              ).map(i => ({ key: i, value: i, text: i }))}
              placeholder="response_types_supported"
              search
              multiple
              selection
              fluid
              allowAdditions
              value={this.props.metadata.response_types_supported || []}
              onAddItem={(_, { value }) => {
                this.props.onChange(
                  Object.assign({}, this.props.metadata, {
                    response_types_supported: Array.from(
                      new Set([
                        ...this.props.metadata.response_types_supported,
                        value as Provider.ResponseType,
                      ])
                    ),
                  })
                );
              }}
              onChange={(_, data) => {
                this.props.onChange(
                  Object.assign({}, this.props.metadata, {
                    response_types_supported: [
                      ...((data.value as Provider.ResponseType[]) || []),
                    ],
                  })
                );
              }}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label htmlFor="scopes_supported">scopes_supported</label>
            <Dropdown
              options={Array.from(this.props.metadata.scopes_supported).map(
                i => ({
                  key: i,
                  value: i,
                  text: i,
                })
              )}
              placeholder="scopes_supported"
              search
              multiple
              selection
              fluid
              allowAdditions
              value={this.props.metadata.scopes_supported || []}
              onAddItem={(_, { value }) => {
                this.props.onChange(
                  Object.assign({}, this.props.metadata, {
                    scopes_supported: Array.from(
                      new Set([
                        value as string,
                        ...this.props.metadata.scopes_supported,
                      ])
                    ),
                  })
                );
              }}
              onChange={(_, data) => {
                this.props.onChange(
                  Object.assign({}, this.props.metadata, {
                    scopes_supported: [...((data.value as string[]) || [])],
                  })
                );
              }}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label htmlFor="acr_values_supported">acr_values_supported</label>
            <Dropdown
              options={Array.from(this.props.metadata.acr_values_supported).map(
                i => ({
                  key: i,
                  value: i,
                  text: i,
                })
              )}
              placeholder="acr_values_supported"
              search
              multiple
              selection
              fluid
              allowAdditions
              value={this.props.metadata.acr_values_supported || []}
              onAddItem={(_, { value }) => {
                this.props.onChange(
                  Object.assign({}, this.props.metadata, {
                    acr_values_supported: Array.from(
                      new Set([
                        ...this.props.metadata.acr_values_supported,
                        value as string,
                      ])
                    ),
                  })
                );
              }}
              onChange={(_, data) => {
                this.props.onChange(
                  Object.assign({}, this.props.metadata, {
                    acr_values_supported: [...((data.value as string[]) || [])],
                  })
                );
              }}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label htmlFor="claims_supported">claims_supported</label>
            <Dropdown
              options={Array.from(this.props.metadata.claims_supported).map(
                i => ({
                  key: i,
                  value: i,
                  text: i,
                })
              )}
              placeholder="claims_supported"
              search
              multiple
              selection
              fluid
              allowAdditions
              value={this.props.metadata.claims_supported || []}
              onAddItem={(_, { value }) => {
                this.props.onChange(
                  Object.assign({}, this.props.metadata, {
                    claims_supported: Array.from(
                      new Set([
                        ...this.props.metadata.claims_supported,
                        value as string,
                      ])
                    ),
                  })
                );
              }}
              onChange={(_, data) => {
                this.props.onChange(
                  Object.assign({}, this.props.metadata, {
                    claims_supported: [...((data.value as string[]) || [])],
                  })
                );
              }}
            />
          </Form.Field>
        </Form.Group>
      </>
    );
  }
};

const ItemJWKS = class ItemFeatures extends Component<{
  metadata: Provider.IModel;
  onChange: (metadata: Provider.IModel) => void;
}> {
  render() {
    return (
      <>
        <Form.Group widths="equal">
          <Form.Field>
            <label htmlFor="jwks_uri">jwks_uri</label>
            <Input
              placeholder="jwks_uri"
              id="jwks_uri"
              onChange={e => {
                this.props.onChange(
                  Object.assign({}, this.props.metadata, {
                    jwks_uri: e.target.value,
                  })
                );
              }}
              value={this.props.metadata.jwks_uri}
              action
            >
              <input />
              <Button
                onClick={() => {
                  this.setState({ loading: true });
                  fetch(this.props.metadata.jwks_uri)
                    .then(resp => resp.json())
                    .then(resp => {
                      this.props.onChange(
                        Object.assign({}, this.props.metadata, {
                          jwks: resp,
                        })
                      );
                    })
                    .finally(() => this.setState({ loading: false }));
                }}
              >
                Fetch
              </Button>
            </Input>
          </Form.Field>
        </Form.Group>
        {this.props.metadata.jwks ? (
          <CodeBlock
            code={JSON.stringify(this.props.metadata.jwks, null, 2)}
            language="json"
            scroll={true}
            title="Keys"
          />
        ) : (
          ""
        )}
      </>
    );
  }
};

const ItemApis = class ItemApis extends Component<
  { provider: IModel; onSave: (env: IModel) => void },
  {}
> {
  render() {
    return (
      <Api.List
        provider={this.props.provider}
        onSave={this.props.onSave.bind(this)}
      />
    );
  }
};

type ItemProps = {
  id?: string;
  provider: IModel;
  onSave: (env: IModel) => boolean;
};

type ItemState = IModel & {
  open: boolean;
  loading: boolean;
};

const Item = class Item extends Component<ItemProps, ItemState> {
  state = Object.assign({}, this.props.provider, {
    open: false,
    loading: false,
  });
  render() {
    const { onSave, children } = this.props;
    return (
      <Modal
        onClose={() => this.setState({ open: false })}
        onOpen={() => this.setState({ open: true })}
        open={this.state.open}
        trigger={children}
        size="large"
        centered={false}
      >
        <Modal.Header>Provider</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form>
              <Form.Group widths="equal">
                <Form.Field>
                  <label htmlFor="issuer">Name</label>
                  <Input
                    placeholder="Name"
                    id="Name"
                    onChange={e => {
                      this.setState({ name: e.target.value });
                    }}
                    value={this.state.name}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label htmlFor="Issuer">Issuer</label>
                  <Input
                    placeholder="issuer"
                    id="issuer"
                    action
                    onChange={e =>
                      this.setState({
                        metadata: Object.assign(
                          {},
                          { ...this.state.metadata },
                          { issuer: e.target.value }
                        ),
                      })
                    }
                    value={this.state.metadata.issuer}
                  >
                    <input />
                    <Button
                      onClick={() => {
                        this.setState({ loading: true });
                        Provider.fromIssuer(this.state.metadata.issuer)
                          .then(provider => {
                            this.setState({
                              metadata: {
                                ...emptyMetadata(),
                                ...this.state.metadata,
                                ...provider.metadata,
                              },
                              loading: false,
                            });
                          })
                          .catch(error => {
                            console.error(error);
                            this.setState({ loading: false });
                          });
                      }}
                    >
                      Fetch
                    </Button>
                  </Input>
                </Form.Field>
              </Form.Group>

              <Tab
                panes={[
                  {
                    menuItem: "Endpoints",
                    render: () => (
                      <Tab.Pane>
                        <ItemEndpoints
                          metadata={this.state.metadata}
                          onChange={metadata => this.setState({ metadata })}
                        />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: "Features",
                    render: () => (
                      <Tab.Pane>
                        <ItemFeatures
                          metadata={this.state.metadata}
                          onChange={metadata => this.setState({ metadata })}
                        />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: "JWKS",
                    render: () => (
                      <Tab.Pane>
                        <ItemJWKS
                          metadata={this.state.metadata}
                          onChange={metadata => this.setState({ metadata })}
                        />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: "Clients",
                    render: () => (
                      <Tab.Pane>
                        <Client.List
                          provider={this.state}
                          onSave={({ clients }) => this.setState({ clients })}
                        />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: "APIs",
                    render: () => (
                      <Tab.Pane>
                        <ItemApis
                          provider={this.state}
                          onSave={({ apis }) => this.setState({ apis })}
                        />
                      </Tab.Pane>
                    ),
                  },
                ]}
              ></Tab>
            </Form>
            <Semantic.Dimmer active={this.state.loading}>
              <Semantic.Loader>Loading</Semantic.Loader>
            </Semantic.Dimmer>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="blue"
            onClick={() => {
              const info = { ...this.state };
              delete info.loading;
              delete info.open;
              if (!onSave(info)) return;
              this.setState({ open: false });
              // Cleanup the New item state
              if (!this.props.id) {
                this.setState(emptyProvider());
              }
            }}
          >
            Save
          </Button>
          <Button
            floated="left"
            onClick={() => {
              const emptyState = {};
              for (let key of Object.keys(this.state)) emptyState[key] = "";
              this.setState({ ...emptyProvider(), open: true });
            }}
          >
            Clear
          </Button>
          <Button
            color="red"
            onClick={() =>
              this.setState({ ...this.props.provider, open: false })
            }
          >
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
};

type HeaderProps = {
  active: string;
  providers: { [key: string]: IModel };
  onActivate: (key: string) => void;
  onAdd: (env: IModel) => boolean;
  onSave: (key: string, env: IModel) => boolean;
  onDelete: (key: string, env: IModel) => void;
};

type HeaderState = {
  open: boolean;
};

const Header = class extends Component<HeaderProps, HeaderState> {
  state = { open: false } as HeaderState;
  render() {
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            <Form>
              <Form.Group widths="equal" style={{ margin: 0 }}>
                <Form.Field>
                  <label htmlFor="providers">Providers</label>
                  <Dropdown
                    id="providers"
                    placeholder="Select Provider"
                    fluid
                    selection
                    value={this.props.active}
                    options={Object.entries(this.props.providers).map(
                      ([key, value]) => {
                        return {
                          key: key,
                          text: value.name,
                          value: key,
                        };
                      }
                    )}
                    onChange={(_, { value }) =>
                      this.props.onActivate(value as string)
                    }
                  />
                </Form.Field>
                <Modal
                  onClose={() => this.setState({ open: false })}
                  onOpen={() => this.setState({ open: true })}
                  open={this.state.open}
                  centered={false}
                  trigger={
                    <Button
                      floated="right"
                      size="mini"
                      icon="setting"
                      content="Manage"
                    />
                  }
                  size="large"
                >
                  <Modal.Content>
                    <Modal.Description>
                      <List
                        providers={this.props.providers}
                        onAdd={this.props.onAdd.bind(this)}
                        onSave={this.props.onSave.bind(this)}
                        onDelete={this.props.onDelete.bind(this)}
                      />
                    </Modal.Description>
                  </Modal.Content>
                </Modal>
              </Form.Group>
            </Form>
          </Card.Header>
        </Card.Content>
      </Card>
    );
  }
};

type ListProps = {
  providers: { [key: string]: IModel };
  onAdd: (env: IModel) => boolean;
  onSave: (key: string, env: IModel) => boolean;
  onDelete: (key: string, env: IModel) => void;
};
const List = class extends Component<ListProps, {}> {
  render() {
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            <span>Provider</span>
            <Item
              provider={emptyProvider()}
              onSave={env => {
                if (
                  Object.entries(this.props.providers).find(
                    ([_, e]) => env.metadata.issuer === e.metadata.issuer
                  )
                )
                  return false;

                return this.props.onAdd(env);
              }}
            >
              <Button icon="add" floated="right" circular color="blue" />
            </Item>
          </Card.Header>
        </Card.Content>
        <Card.Content>
          {!Object.keys(this.props.providers).length ? (
            "Nothing to show"
          ) : (
            <Menu vertical fluid>
              {Object.entries(this.props.providers).map(([key, env]) => {
                return (
                  <Menu.Item key={key} as="a">
                    <Grid columns={2}>
                      <Item
                        id={key}
                        provider={env}
                        onSave={env => {
                          return this.props.onSave(key, env);
                        }}
                      >
                        <Grid.Row>
                          <Grid.Column verticalAlign="middle">
                            <span>{env.name}</span>
                          </Grid.Column>
                          <Grid.Column>
                            <Button.Group size="small" floated="right">
                              <Button
                                negative
                                icon="trash alternate"
                                onClick={e => {
                                  e.stopPropagation();
                                  this.props.onDelete(key, env);
                                }}
                              />
                            </Button.Group>
                          </Grid.Column>
                        </Grid.Row>
                      </Item>
                    </Grid>
                  </Menu.Item>
                );
              })}
            </Menu>
          )}
        </Card.Content>
      </Card>
    );
  }
};

const getUserName = function getUserName(
  provider: IModel,
  user: Provider.User
) {
  if (user.sub === user.client_id)
    return getAudienceName(provider, user.client_id);
  const id_token_set = user.tokens[user.client_id];
  if (id_token_set && id_token_set.profile)
    return `${id_token_set.profile.given_name} ${id_token_set.profile.family_name}`;
  return user.sub;
};
const getAudienceName = function getAudienceName(
  provider: IModel,
  aud: string
) {
  const client = Object.entries(provider.clients.list).find(
    ([_, value]) => value.client_id === aud
  );
  return (client && client[1].client_name) || aud;
};

export { IModel, List, Item, Header, getUserName, getAudienceName };
