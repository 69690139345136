import React, { Component } from "react";
import {
  Dropdown,
  Form,
  Input,
  Button,
  Card,
  Header,
  Dimmer,
  Loader,
} from "semantic-ui-react";

import * as ProviderUi from "../provider";
import * as RequestUi from "./index";

import * as Authorization from "../../../tools/oauth2/request";
import { ApiType } from "../provider/api";
import CodeBlock from "../../codeblock";

const empty = function(name: string): IRequestModel {
  return {
    type: RequestUi.RequestType.client_credentials,
    name: name,
    scopes: [] as string[],
    client_key: "",
  };
};

type IRequestModel = RequestUi.IBase & {
  type: RequestUi.RequestType.client_credentials;
  audience?: string;
  resource?: string;
};

type RequestProps = {
  id?: string;
  provider: ProviderUi.IModel;
  request: IRequestModel;
  onSave: (request: IRequestModel) => void;
};

const Request = class extends Component<RequestProps, { loading: boolean, response: any }> {
  state = { loading: false, response : null };
  save(request: IRequestModel) {
    this.props.onSave({ ...request });
  }
  render() {
    const client = this.props.provider.clients?.list[
      this.props.request.client_key
    ];
    const target = this.props.request.audience || this.props.request.resource;
    const api = Object.entries(this.props.provider.apis.list).find(([_, value]) => value.ref === target);
    return (
      <Card fluid style={{ boxShadow: "none" }}>
        <Card.Content>
          <Dimmer active={this.state.loading}>
            <Loader>Loading</Loader>
          </Dimmer>
          <Form>
            <Form.Group widths="equal">
              <Form.Field>
                <label htmlFor="name">name</label>
                <Input
                  placeholder="name"
                  id="name"
                  onChange={e => {
                    this.save(
                      Object.assign({}, this.props.request, {
                        name: e.target.value,
                      })
                    );
                  }}
                  value={this.props.request.name}
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="client_id">client_id</label>
                <Dropdown
                  id="client_id"
                  options={Object.entries(this.props.provider.clients.list).map(
                    ([id, client]) => ({
                      key: id,
                      value: id,
                      text: client.client_name,
                      content: (
                        <Header
                          size="small"
                          content={client.client_name}
                          subheader={client.client_id}
                        />
                      ),
                    })
                  )}
                  placeholder="client_id"
                  selection
                  fluid
                  value={client ? this.props.request.client_key : ""}
                  onChange={async (_, { value }: { value: string }) => {
                    this.save(
                      Object.assign({}, this.props.request, {
                        client_key: value,
                      })
                    );
                  }}
                />
              </Form.Field>
            </Form.Group>
            {client ? (
              <Form.Group widths="equal">
                <Form.Field>
                  <label htmlFor="scopes">scopes</label>
                  <Dropdown
                    id="scopes"
                    options={client.scopes.map(scp => ({
                      key: scp,
                      value: scp,
                      text: scp,
                    }))}
                    placeholder="scopes"
                    multiple
                    selection
                    fluid
                    value={this.props.request.scopes}
                    onChange={async (_, { value }: { value: string }) => {
                      this.save(
                        Object.assign({}, this.props.request, {
                          scopes: value,
                        })
                      );
                    }}
                  />
                </Form.Field>
              </Form.Group>
            ) : (
              ""
            )}{" "}
            <Form.Group widths="equal">
              {Object.keys(this.props.provider.apis.list).length ?
                <Form.Field>
                  <label htmlFor="apis">API</label>
                  <Dropdown
                    id="apis"
                    options={Object.entries(this.props.provider.apis.list).map(
                      ([key, api]) => ({
                        key,
                        value: key,
                        text: ProviderUi.getAudienceName(this.props.provider, api.ref),
                      })
                    )}
                    placeholder="apis"
                    selection
                    clearable
                    fluid
                    value={(api && api[0]) || ""}
                    onChange={async (_, { value }: { value: string }) => {
                      const api = this.props.provider.apis.list[value];
                      if (!api) {
                        this.save(
                          Object.assign({}, this.props.request, {
                            audience: null,
                            resource: null,
                          })
                        );
                      } else if (api.type === ApiType.audience) {
                        this.save(
                          Object.assign({}, this.props.request, {
                            audience: api.ref,
                            resource: null,
                          })
                        );
                      } else if (api.type === ApiType.resource) {
                        this.save(
                          Object.assign({}, this.props.request, {
                            audience: null,
                            resource: api.ref,
                          })
                        );
                      }
                    }}
                  />
                </Form.Field>
              : ""}
            </Form.Group>
          </Form>
          <Button
            fluid
            color="green"
            onClick={async () => {
              this.setState({ loading: true });
              const request = this.props.request;
              if (!request.scopes || request.scopes.length === 0) delete request.scopes;
              const response = await new Authorization.ClientCredential(
                this.props.provider.metadata,
                client
              ).execute({
                ...this.props.request
              });
              this.setState({ response, loading: false });
            }}
          >
            Run
          </Button>
          {this.state.response ? (
            <CodeBlock
              code={JSON.stringify(this.state.response, null, 2)}
              language="json"
            />
          ) : (
            ""
          )}
        </Card.Content>
      </Card>
    );
  }
};

export { Request, IRequestModel, empty };
