import React, { Component } from "react";
import {
  Button,
  Card,
  Form,
  Modal,
  Input,
  Grid,
  Dropdown,
  Menu,
  Icon
} from "semantic-ui-react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import * as Provider from ".";
import { uuidv4 } from "../../../tools/oauth2/utils";

import * as Client from "../../../tools/oauth2/client";
import * as AuthorizationServer from "../../../tools/oauth2/provider";

const empty = function empty(): IModel {
  return Object.assign(
    {},
    {
      active: false,
      client_id: "",
      client_name: "",
      client_secret: "",
      scopes: [],
      token_endpoint_auth_method:
        AuthorizationServer.ClientAuthenticationMethod.client_secret_post,
    }
  );
};

type IModel = Client.IModel & {
  id?: string;
  active: boolean;
  client_name: string;
};

type ItemProps = {
  client: IModel;
  provider: Provider.IModel;
  onSave: (env: IModel) => void;
};

type ItemState = IModel & {
  open: boolean;
  scopes_supported: Set<string>;
  token_endpoint_auth_methods_supported: Set<string>;
};
const Item = class Item extends Component<ItemProps, ItemState> {
  state = {
    ...this.props.client,
    open: false,
    scopes_supported: new Set([
      ...(this.props.provider.metadata.scopes_supported || []),
      ...(this.props.client.scopes || []),
    ]),
    token_endpoint_auth_methods_supported: new Set([
      ...(this.props.provider.metadata.token_endpoint_auth_methods_supported ||
        []),
    ]),
  };
  render() {
    const { onSave, children } = this.props;
    const {
      open,
      client_id,
      client_name,
      client_secret,
      scopes,
      token_endpoint_auth_method,
      code_challenge_method,
    } = this.state;
    const redirect_uri = window.location.protocol + '//' + window.location.host + window.location.pathname;
    return (
      <Modal
        onClose={() => this.setState({ open: false })}
        onOpen={() => this.setState({ open: true })}
        open={open}
        trigger={children}
        centered={false}
      >
        <Modal.Header>Client</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form>
              <Form.Group widths="equal">
                <Form.Field>
                  <label htmlFor="redirect_uri">redirect_uri</label>
                  <Input
                    placeholder="redirect_uri"
                    id="redirect_uri"
                    icon={
                      <CopyToClipboard text={redirect_uri} onCopy={() => console.log('copied')}>
                        <Icon name="copy" link />
                      </CopyToClipboard>
                    }
                    value={redirect_uri}
                    readOnly
                    ></Input>
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label htmlFor="client_name">client_name</label>
                  <Input
                    placeholder="client_name"
                    id="client_name"
                    onChange={e => {
                      this.setState({ client_name: e.target.value });
                    }}
                    value={client_name}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label htmlFor="client_id">client_id</label>
                  <Input
                    placeholder="client_id"
                    id="client_id"
                    onChange={e => this.setState({ client_id: e.target.value })}
                    value={client_id}
                  />
                </Form.Field>
                <Form.Field>
                  <label htmlFor="client_secret">client_secret</label>
                  <Input
                    placeholder="client_secret"
                    id="client_secret"
                    onChange={e =>
                      this.setState({ client_secret: e.target.value })
                    }
                    value={client_secret}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>scopes</label>
                  <Dropdown
                    options={Array.from(
                      this.state.scopes_supported
                    ).map(scope => ({ key: scope, value: scope, text: scope }))}
                    placeholder="scopes"
                    search
                    multiple
                    selection
                    fluid
                    allowAdditions
                    value={scopes || []}
                    onAddItem={(_, { value }) => {
                      this.setState(prevState => ({
                        scopes_supported: new Set([
                          value as string,
                          ...prevState.scopes_supported,
                        ]),
                      }));
                    }}
                    onChange={(_, data) => {
                      this.setState({
                        scopes: [...((data.value as string[]) || [])],
                      });
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>token_endpoint_auth_method</label>
                  <Dropdown
                    options={Array.from(
                      this.state.token_endpoint_auth_methods_supported
                    ).map(method => ({
                      key: method,
                      value: method,
                      text: method,
                    }))}
                    placeholder="token_endpoint_auth_method"
                    search
                    selection
                    fluid
                    allowAdditions
                    clearable
                    value={token_endpoint_auth_method}
                    onAddItem={(_, { value }: { value: string }) => {
                      if (
                        [
                          "client_secret_basic",
                          "client_secret_post",
                          "client_secret_jwt",
                          "private_key_jwt",
                        ].indexOf(value) === -1
                      )
                        return;
                      this.setState(prevState => ({
                        token_endpoint_auth_methods_supported: new Set([
                          value as string,
                          ...prevState.token_endpoint_auth_methods_supported,
                        ]),
                      }));
                    }}
                    onChange={(_, data) => {
                      this.setState({
                        token_endpoint_auth_method: data.value as AuthorizationServer.ClientAuthenticationMethod,
                      });
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Code Challenge Method</label>
                  <Dropdown
                    options={Object.keys(
                      AuthorizationServer.CodeChallengeMethod
                    ).map(method => {
                      return {
                        text: method,
                        value: method,
                        key: method,
                      };
                    })}
                    placeholder="code_challenge_method"
                    clearable
                    search
                    selection
                    fluid
                    value={code_challenge_method}
                    onChange={(_, data) => {
                      this.setState({
                        code_challenge_method: data.value as AuthorizationServer.CodeChallengeMethod,
                      });
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="blue"
            onClick={() => {
              this.setState({ open: false });
              const {
                id: key,
                active,
                client_id,
                client_name,
                client_secret,
                scopes,
                token_endpoint_auth_method,
                code_challenge_method,
              } = this.state;
              // Cleanup the New item state
              if (!key) this.setState(empty());
              onSave({
                id: key,
                active,
                client_id,
                client_name,
                client_secret,
                redirect_uri: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
                scopes,
                token_endpoint_auth_method,
                code_challenge_method,
              });
            }}
          >
            Save
          </Button>
          <Button color="red" onClick={() => this.setState({ open: false })}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
};

type ListProps = {
  provider: Provider.IModel;
  onSave: (env: Provider.IModel) => void;
};
type ListState = {};

const List = class extends Component<ListProps, ListState> {
  activate(key: string) {
    const env = Object.assign(
      {},
      { ...this.props.provider },
      { clients: { list: this.props.provider.clients.list, active: key } }
    );
    this.props.onSave(env);
  }

  save(key: string, client: IModel) {
    let clients = this.props.provider.clients
      ? {
          active: this.props.provider.clients.active,
          list: { ...this.props.provider.clients.list },
        }
      : { list: {} };
    if (!key) {
      clients.list[uuidv4()] = client;
    } else {
      clients.list[key] = client;
    }
    const env = Object.assign({ ...this.props.provider }, { clients });
    this.props.onSave(env);
  }

  delete(key: string) {
    let clients = this.props.provider.clients
      ? {
          active: this.props.provider.clients.active,
          list: { ...this.props.provider.clients.list },
        }
      : { list: {} };

    delete clients.list[key];

    const env = Object.assign({ ...this.props.provider }, { clients });
    this.props.onSave(env);
  }

  render() {
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            <span>Clients</span>
            <Item
              client={empty()}
              provider={this.props.provider}
              onSave={client => this.save(null, client)}
            >
              <Button floated="right" size="mini" icon="add" />
            </Item>
          </Card.Header>
        </Card.Content>
        <Card.Content>
          {!Object.keys(this.props.provider.clients?.list || {}).length ? (
            "Nothing to show"
          ) : (
            <Menu vertical fluid>
              {Object.entries(this.props.provider.clients?.list || {}).map(
                ([key, client]) => {
                  return (
                    <Menu.Item key={key} as="a">
                      <Grid columns={2}>
                        <Item
                          provider={this.props.provider}
                          client={client}
                          onSave={client => this.save(key, client)}
                        >
                          <Grid.Row>
                            <Grid.Column verticalAlign="middle">
                              <span>{client.client_name}</span>
                            </Grid.Column>
                            <Grid.Column>
                              <Button.Group size="small" floated="right">
                                <Button
                                  negative
                                  icon="trash alternate"
                                  onClick={e => {
                                    e.stopPropagation();
                                    this.delete(key);
                                  }}
                                />
                              </Button.Group>
                            </Grid.Column>
                          </Grid.Row>
                        </Item>
                      </Grid>
                    </Menu.Item>
                  );
                }
              )}
            </Menu>
          )}
        </Card.Content>
      </Card>
    );
  }
};

export { IModel, List, Item };
