import React, { Component } from "react";
import {
  Dropdown,
  Form,
  Input,
  Button,
  Card,
  Header,
  Divider,
} from "semantic-ui-react";
import {
  CodeChallengeMethod,
  ResponseType,
} from "../../../tools/oauth2/provider";
import { uuidv4, sha256 } from "../../../tools/oauth2/utils";

import * as ProviderUi from "../provider";
import * as RequestUi from "./index";
import * as AuthorizationCodeUi from "./authorization_code";

import * as Authorization from "../../../tools/oauth2/request";
import Provider from "../../../tools/oauth2/provider";
import { ApiType } from "../provider/api";

const empty = function(name: string): IRequestModel {
  return {
    name,
    type: RequestUi.RequestType.authorization,
    scopes: [],
    response_type: ResponseType.code,
    prompt: [],
    login_hint: "",
    acr_values: [],
    client_key: "",
    code_challenge: "",
    code_verifier: "",
    max_age: 0,
    nonce: "",
  };
};

type IRequestModel = RequestUi.IBase &
  Authorization.RequestBuildProps & {
    code_challenge?: string;
    type: RequestUi.RequestType.authorization;
    response?: Authorization.Response;
  };

type RequestProps = {
  id?: string;
  provider: ProviderUi.IModel;
  request: IRequestModel;
  onSave: (request: IRequestModel) => void;
};

const Request = class extends Component<RequestProps, {}> {
  save(request: IRequestModel) {
    this.props.onSave({ ...request });
  }
  render() {
    const client = this.props.provider.clients?.list[
      this.props.request.client_key
    ];
    const target = this.props.request.audience || this.props.request.resource;
    const api = Object.entries(this.props.provider.apis?.list).find(
      ([_, value]) => value.ref === target
    );

    return (
      <Card fluid style={{ boxShadow: "none" }}>
        <Card.Content>
          <Form
            onSubmit={async e => {
              e.preventDefault();
              await new Authorization.Authorization(
                new Provider(this.props.provider.metadata),
                client
              ).execute({
                ...(this.props.request as Authorization.RequestBuildProps),
                state: { key: this.props.id },
                scopes: this.props.request.scopes.filter(scp => client.scopes.indexOf(scp) > -1)
              });
            }}
          >
            <Form.Group widths="equal">
              <Form.Field>
                <label htmlFor="name">name</label>
                <Input
                  placeholder="name"
                  id="name"
                  onChange={e => {
                    this.save(
                      Object.assign({}, this.props.request, {
                        name: e.target.value,
                      })
                    );
                  }}
                  value={this.props.request.name}
                />
              </Form.Field>
            </Form.Group>
            <Header as="h3">General</Header>
            <Divider />
            <Form.Group widths="equal">
              <Form.Field>
                <label htmlFor="client_id">client_id</label>
                <Dropdown
                  id="client_id"
                  options={Object.entries(this.props.provider.clients.list).map(
                    ([id, client]) => ({
                      key: id,
                      value: id,
                      text: client.client_name,
                      content: (
                        <Header
                          size="small"
                          content={client.client_name}
                          subheader={client.client_id}
                        />
                      ),
                    })
                  )}
                  placeholder="client_id"
                  selection
                  fluid
                  value={client ? this.props.request.client_key : ""}
                  onChange={async (_, { value }: { value: string }) => {
                    this.save(
                      Object.assign({}, this.props.request, {
                        client_key: value,
                      })
                    );
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="response_type">response_type</label>
                <Dropdown
                  id="prompt"
                  options={this.props.provider.metadata.response_types_supported.map(
                    i => ({
                      key: i,
                      value: i,
                      text: i,
                    })
                  )}
                  placeholder="response_type"
                  selection
                  fluid
                  value={this.props.request.response_type}
                  onChange={(_, { value }: { value: string }) => {
                    this.props.onSave(
                      Object.assign({}, this.props.request, {
                        response_type: value,
                      })
                    );
                  }}
                />
              </Form.Field>
            </Form.Group>

            <Header as="h3">Authentication</Header>
            <Divider />

            <Form.Group widths="equal">
              <Form.Field>
                <label htmlFor="prompt">prompt</label>
                <Dropdown
                  id="prompt"
                  options={["login", "consent", "none"].map(i => ({
                    key: i,
                    value: i,
                    text: i,
                  }))}
                  placeholder="prompt"
                  search
                  selection
                  fluid
                  multiple
                  value={this.props.request?.prompt}
                  onChange={(_, { value }: { value: string[] }) => {
                    this.props.onSave(
                      Object.assign({}, this.props.request, {
                        prompt: value,
                      })
                    );
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="max_age">max_age</label>
                <Input
                  placeholder="max_age"
                  id="max_age"
                  type="number"
                  min="30"
                  onChange={e => {
                    this.props.onSave(
                      Object.assign({}, this.props.request, {
                        max_age: e.target.value,
                      })
                    );
                  }}
                  value={this.props.request.max_age || ""}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label htmlFor="acr_values">acr_values</label>
                <Dropdown
                  id="prompt"
                  options={(
                    this.props.provider.metadata.acr_values_supported || []
                  ).map(i => ({
                    key: i,
                    value: i,
                    text: i,
                  }))}
                  placeholder="acr_values"
                  search
                  selection
                  fluid
                  multiple
                  value={this.props.request?.acr_values}
                  onChange={(_, { value }: { value: string[] }) => {
                    this.props.onSave(
                      Object.assign({}, this.props.request, {
                        acr_values: value,
                      })
                    );
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="login_hint">login_hint</label>
                <Input
                  placeholder="login_hint"
                  id="login_hint"
                  onChange={e => {
                    this.props.onSave(
                      Object.assign({}, this.props.request, {
                        login_hint: e.target.value,
                      })
                    );
                  }}
                  value={this.props.request.login_hint}
                />
              </Form.Field>
            </Form.Group>

            <Header as="h3">Security</Header>
            <Divider />
            {client?.code_challenge_method === CodeChallengeMethod.S256 &&
            this.props.request.response_type.indexOf("code") > -1 ? (
              <Form.Group widths="equal">
                <Form.Field>
                  <Form.Input
                    label="code_verifier"
                    placeholder="code_verifier"
                    id="code_verifier"
                    value={this.props.request.code_verifier}
                    action
                    error={
                      client?.code_challenge_method ===
                        CodeChallengeMethod.S256 &&
                      !this.props.request.code_verifier
                        ? {
                            content: "You must generate a code_verifier",
                            pointing: "above",
                          }
                        : false
                    }
                  >
                    <input readOnly />
                    <Button
                      icon="refresh"
                      onClick={async e => {
                        e.preventDefault();
                        const code_verifier = uuidv4();
                        this.props.onSave(
                          Object.assign({}, this.props.request, {
                            code_verifier: code_verifier,
                            code_challenge: await sha256(code_verifier),
                          })
                        );
                      }}
                    />
                  </Form.Input>
                </Form.Field>
                <Form.Field>
                  <label htmlFor="code_challenge">code_challenge</label>
                  <Input
                    placeholder="code_challenge"
                    id="code_challenge"
                    value={this.props.request.code_challenge}
                  >
                    <input readOnly />
                  </Input>
                </Form.Field>
              </Form.Group>
            ) : (
              ""
            )}
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Input
                  label="nonce"
                  placeholder="nonce"
                  id="nonce"
                  value={this.props.request.nonce}
                  action
                >
                  <input readOnly />
                  <Button
                    icon="refresh"
                    onClick={async e => {
                      e.preventDefault();
                      const nonce = uuidv4();
                      this.props.onSave(
                        Object.assign({}, this.props.request, {
                          nonce,
                        })
                      );
                    }}
                  />
                </Form.Input>
              </Form.Field>
              <Form.Field></Form.Field>
            </Form.Group>
            <Header as="h3">Authorization</Header>
            <Divider />
            {client ? (
              <Form.Group widths="equal">
                <Form.Field>
                  <label htmlFor="scopes">scopes</label>
                  <Dropdown
                    id="scopes"
                    options={client.scopes.map(scp => ({
                      key: scp,
                      value: scp,
                      text: scp,
                    }))}
                    placeholder="scopes"
                    multiple
                    selection
                    fluid
                    value={this.props.request.scopes.filter(scp => client.scopes.indexOf(scp) > -1)}
                    onChange={async (_, { value }: { value: string }) => {
                      this.save(
                        Object.assign({}, this.props.request, {
                          scopes: value,
                        })
                      );
                    }}
                  />
                </Form.Field>
              </Form.Group>
            ) : (
              ""
            )}
            <Form.Group widths="equal">
              {Object.keys(this.props.provider.apis.list).length?
                <Form.Field>
                  <label htmlFor="apis">API</label>
                  <Dropdown
                    id="apis"
                    options={Object.entries(this.props.provider.apis.list).map(
                      ([key, api]) => ({
                        key,
                        value: key,
                        text: ProviderUi.getAudienceName(
                          this.props.provider,
                          api.ref
                        ),
                      })
                    )}
                    placeholder="apis"
                    selection
                    clearable
                    fluid
                    value={(api && api[0]) || ""}
                    onChange={async (_, { value }: { value: string }) => {
                      const api = this.props.provider.apis.list[value];
                      if (!api) {
                        this.save(
                          Object.assign({}, this.props.request, {
                            audience: null,
                            resource: null,
                          })
                        );
                      } else if (api.type === ApiType.audience) {
                        this.save(
                          Object.assign({}, this.props.request, {
                            audience: api.ref,
                            resource: null,
                          })
                        );
                      } else if (api.type === ApiType.resource) {
                        this.save(
                          Object.assign({}, this.props.request, {
                            audience: null,
                            resource: api.ref,
                          })
                        );
                      }
                    }}
                  />
                </Form.Field>
              : ""}
            </Form.Group>
            {!this.props.request.response ? (
              <Button fluid color="green" type="submit">
                Login
              </Button>
            ) : (
              ""
            )}
          </Form>
          {this.props.request.response ? (
            <AuthorizationCodeUi.Request
              request={this.props.request}
              id={this.props.id}
              provider={this.props.provider}
              onSave={this.props.onSave.bind(this)}
            />
          ) : (
            ""
          )}
        </Card.Content>
      </Card>
    );
  }
};

export { Request, empty, IRequestModel };
