import React, { Component } from "react";
import { Button, Card, Grid, Checkbox, Input } from "semantic-ui-react";
import * as Semantic from "semantic-ui-react";

import * as Provider from ".";
import { uuidv4 } from "../../../tools/oauth2/utils";

type IModel = {
  type: ApiType;
  ref: string;
};
enum ApiType {
  resource = "resource",
  audience = "audience",
}

type ListProps = {
  provider: Provider.IModel;
  onSave: (env: Provider.IModel) => void;
};

class List extends Component<ListProps> {
  save(key: string, api: IModel) {
    let apis = this.props.provider.apis
      ? {
          list: { ...this.props.provider.apis.list },
        }
      : { list: {} };
    if (!key) {
      apis.list[uuidv4()] = api;
    } else {
      apis.list[key] = api;
    }
    const env = Object.assign({ ...this.props.provider }, { apis });
    this.props.onSave(env);
  }
  delete(key: string) {
    let apis = this.props.provider.apis
      ? {
          list: { ...this.props.provider.apis.list },
        }
      : { list: {} };

    delete apis.list[key];

    const env = Object.assign({ ...this.props.provider }, { apis });
    this.props.onSave(env);
  }
  render() {
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            <span>APIs</span>
            <Button
              floated="right"
              size="mini"
              icon="add"
              onClick={() =>
                this.save(null, { type: ApiType.audience, ref: "" })
              }
            />
          </Card.Header>
        </Card.Content>
        <Card.Content>
          {!Object.keys(this.props.provider.apis?.list || {}).length ? (
            "Nothing to show"
          ) : (
            <Semantic.List divided>
              {Object.entries(this.props.provider.apis?.list || {}).map(
                ([key, api]) => {
                  return (
                    <Semantic.List.Item key={key}>
                      <Semantic.List.Content>
                        <Grid>
                          <Grid.Row>
                            <Grid.Column verticalAlign="middle">
                              <Checkbox
                                toggle
                                onChange={(_, { checked }) =>
                                  this.save(key, {
                                    type: checked
                                      ? ApiType.audience
                                      : ApiType.resource,
                                    ref: api.ref,
                                  })
                                }
                                checked={api.type === ApiType.audience}
                              />
                              {api.type}
                            </Grid.Column>
                            <Grid.Column style={{ flexGrow: 1 }}>
                              <Input
                                placeholder="Reference"
                                fluid
                                id="reference"
                                onChange={e => {
                                  this.save(key, {
                                    type: api.type,
                                    ref: e.target.value,
                                  });
                                }}
                                value={api.ref}
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Button
                                negative
                                icon="trash alternate"
                                onClick={() => {
                                  this.delete(key);
                                }}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Semantic.List.Content>
                    </Semantic.List.Item>
                  );
                }
              )}
            </Semantic.List>
          )}
        </Card.Content>
      </Card>
    );
  }
}

export { List, IModel, ApiType };
