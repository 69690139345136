import React, { Component } from "react";
import Layout from "../components/Layout";

import PlaygroundUi from '../components/tools2';

const Playground = class extends Component {

  render() {
    return (
      <Layout section="playground" title="Playground">
        <PlaygroundUi />
      </Layout>
    )
  }
}


export default Playground;