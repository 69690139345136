import React, { Component } from "react";

import { Request as AuthRequest} from "../../../tools/oauth2/utils";
import CodeBlock from "../../codeblock";


type HttpRequestProps = {
  request: AuthRequest;
  title: string;
  actions?: React.ReactElement
};

const HttpRequest = class extends Component<
  HttpRequestProps,
  {}
> {
  render() {
    if (!this.props.request) return <div></div>;
    const param = new URL(this.props.request.url);
    // @ts-ignore
    let query = Object.entries(this.props.request.body);
    let request, url;
    if (this.props.request.type === 'redirect') {
      request = `${param.protocol}//${param.host}${param.pathname}?
    ${query.map(([key, value]) => `${key}=${value}`).join("&\r\n  ")}`;
      url = `${param.protocol}//${param.host}${param.pathname}?${query
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`
        )
        .join("&")}`;
    } else {
      request = `${this.props.request.method} ${param.pathname}
Host: ${param.host}
${Object.entries(this.props.request.headers).map(([key, value]) => key+':' +value).join('\r\n')}
${Object.entries(this.props.request.body).map(([key, value]) => key+'=' +value).join('&\r\n')}`
    }

    return (
      <CodeBlock
        code={request}
        language="http"
        scroll={true}
        title={this.props.title}
        actionBar={this.props.actions}
      />
    );
  }
};

export {
  HttpRequest
}
