import React, { Component } from "react";
import {
  Card,
  Grid,
  Button,
  Header,
  Menu,
  Image,
  Table,
} from "semantic-ui-react";

import * as ProviderUi from "../provider";
import { Playground, PlaygroundSection } from "../index";
import Provider, { User } from "../../../tools/oauth2/provider";
import CodeBlock from "../../codeblock";

class Item extends Component<
  { user: User; provider: ProviderUi.IModel },
  { audience?: string }
> {
  state = { audience: null };

  getAudienceName(aud: string) {
    const client = Object.entries(this.props.provider.clients.list).find(
      ([_, value]) => value.client_id === aud
    );
    return (client && client[1].client_name) || aud;
  }

  render() {
    return Object.entries(this.props.user.tokens).map(([aud, tokenSet]) => {
      const {access_token, id_token, refresh_token, token_type, expires_in, scope} = tokenSet;
      return (
        <React.Fragment key={aud}>
          <Table celled fixed verticalAlign="top">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{this.getAudienceName(aud)}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell verticalAlign="top">
                  <CodeBlock
                    code={JSON.stringify({access_token, id_token, refresh_token, token_type, expires_in, scope}, null, 2)}
                    language="json"
                    scroll={true}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </React.Fragment>
      );
    });
  }
}

type ListProps = {
  provider: ProviderUi.IModel;
  onDelete: () => void;
};
type ListState = {
  client_id?: string;
  sub?: string;
};
class List extends Component<ListProps, ListState> {
  state: ListState = { client_id: null, sub: null };
  render() {
    const users = new Provider(this.props.provider.metadata).users;
    const selected = this.state.client_id
      ? users.find(
          u => u.client_id === this.state.client_id && u.sub === this.state.sub
        )
      : null;

    return (
      <Grid.Row>
        <Grid.Column width="4" style={{ padding: 0 }}>
          <Menu vertical fluid pointing>
            {!users.length ? (
              <Menu.Item>No Tokens available yet</Menu.Item>
            ) : (
              users.map((entry, id) => {
                const profile = entry.tokens[entry.client_id]?.profile;
                const client = Object.entries(
                  this.props.provider.clients.list
                ).find(([_, value]) => value.client_id === entry.client_id);
                return (
                  <Menu.Item
                    key={id}
                    active={
                      entry.sub === this.state.sub &&
                      entry.client_id === this.state.client_id
                    }
                    onClick={() =>
                      this.setState({
                        client_id: entry.client_id,
                        sub: entry.sub,
                      })
                    }
                  >
                    <Grid verticalAlign="middle">
                      <Grid.Row>
                        <Grid.Column
                          verticalAlign="middle"
                          style={{ flexGrow: 1 }}
                        >
                          {profile ? (
                            <Header as="h4">
                              {profile.picture ? (
                                <Image circular src={profile.picture} />
                              ) : (
                                ""
                              )}
                              <Header.Content>
                                {`${profile.given_name} ${profile.family_name}`}
                                <Header.Subheader>
                                  {client[1].client_name}
                                </Header.Subheader>
                              </Header.Content>
                            </Header>
                          ) : (
                            <Header as="h4">
                              <Image circular />
                              {client[1].client_name}
                            </Header>
                          )}
                        </Grid.Column>
                        <Grid.Column style={{ width: "50px" }}>
                          <Button.Group size="small" floated="right">
                            <Button
                              negative
                              icon="trash alternate"
                              onClick={e => {
                                e.stopPropagation();
                                entry.remove();
                                this.props.onDelete();
                              }}
                            />
                          </Button.Group>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Menu.Item>
                );
              })
            )}
          </Menu>
        </Grid.Column>

        <Grid.Column width="12">
          {this.state.client_id ? (
            <Item user={selected} provider={this.props.provider} />
          ) : (
            <Card fluid>
              <Card.Content>Choose a Token Set to get started</Card.Content>
            </Card>
          )}
        </Grid.Column>
      </Grid.Row>
    );
  }
}

class Ui extends Component {
  render() {
    return <Playground section={PlaygroundSection.token} />;
  }
}

export default Ui;
export { List };
