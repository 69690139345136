import React, { Component } from "react";
import {
  Dropdown,
  Form,
  Input,
  Button,
  Card,
  Header,
  Dimmer,
  Loader,
} from "semantic-ui-react";

import * as ProviderUi from "../provider";
import * as RequestUi from "./index";
import { ApiType } from "../provider/api";

import Provider from "../../../tools/oauth2/provider";
import CodeBlock from "../../codeblock";

const empty = function(name: string): IRequestModel {
  return {
    type: RequestUi.RequestType.refresh_token,
    name: name,
    scopes: [] as string[],
    client_key: "",
  };
};

type ITokenModel = {
  audience: string;
  audienceName: string;
  token: string;
  token_hint: "access_token" | "refresh_token";
  sub: string;
  subName: string;
  client_id: string;
  clientName: string;
};

type IRequestModel = RequestUi.IBase & {
  type: RequestUi.RequestType.refresh_token;
  token?: ITokenModel;
  audience?: string;
  resource?: string;
};

type RequestProps = {
  id?: string;
  provider: ProviderUi.IModel;
  request: IRequestModel;
  onSave: (request: IRequestModel) => void;
};

const Request = class extends Component<
  RequestProps,
  { loading: boolean; response: any }
> {
  state = { loading: false, response: null };
  save(request: IRequestModel) {
    this.props.onSave({ ...request });
  }
  render() {
    const client = this.props.provider.clients?.list[
      this.props.request.client_key
    ];
    const provider = new Provider(this.props.provider.metadata);
    const target = this.props.request.audience || this.props.request.resource;
    const api = Object.entries(this.props.provider.apis.list).find(
      ([_, value]) => value.ref === target
    );

    if (
      this.props.request.token &&
      !provider.users.find(u =>
        Object.entries(u.tokens).find(
          ([_, tokens]) =>
            tokens.refresh_token === this.props.request.token.token
        )
      )
    )
      this.save(
        Object.assign({}, this.props.request, {
          token: null,
        })
      );

    return (
      <Card fluid style={{ boxShadow: "none" }}>
        <Card.Content>
          <Dimmer active={this.state.loading}>
            <Loader>Loading</Loader>
          </Dimmer>
          <Form>
            <Form.Group widths="equal">
              <Form.Field>
                <label htmlFor="name">name</label>
                <Input
                  placeholder="name"
                  id="name"
                  onChange={e => {
                    this.save(
                      Object.assign({}, this.props.request, {
                        name: e.target.value,
                      })
                    );
                  }}
                  value={this.props.request.name}
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="client_id">client_id</label>
                <Dropdown
                  id="client_id"
                  options={Object.entries(this.props.provider.clients.list).map(
                    ([id, client]) => ({
                      key: id,
                      value: id,
                      text: client.client_name,
                      content: (
                        <Header
                          size="small"
                          content={client.client_name}
                          subheader={client.client_id}
                        />
                      ),
                    })
                  )}
                  placeholder="client_id"
                  selection
                  fluid
                  value={client ? this.props.request.client_key : ""}
                  onChange={async (_, { value }: { value: string }) => {
                    this.save(
                      Object.assign({}, this.props.request, {
                        client_key: value,
                      })
                    );
                  }}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label htmlFor="refresh_token">Refresh Token</label>
                <Dropdown
                  id="refresh_token"
                  placeholder={
                    this.props.request.token?.token
                      ? `${this.props.request.token.subName} ${
                          this.props.request.token.subName !==
                          this.props.request.token.clientName
                            ? "on " + this.props.request.token.clientName
                            : ""
                        } ${
                          this.props.request.token.audienceName !==
                          this.props.request.token.clientName
                            ? "with target " +
                              this.props.request.token.audienceName
                            : ""
                        }`
                      : "Choose a refresh_token"
                  }
                  value={this.props.request.token?.token}
                  className="selection"
                  fluid
                >
                  <Dropdown.Menu>
                    {provider.users.map(user => {
                      const children = Object.entries(user.tokens)
                        .map(([audience, tokenSet]) => {
                          const resp = [];
                          if (tokenSet.refresh_token)
                            resp.push({
                              audience,
                              audienceName: ProviderUi.getAudienceName(
                                this.props.provider,
                                audience
                              ),
                              token: tokenSet.refresh_token,
                              token_hint: "refresh_token",
                              sub: user.sub,
                              subName: ProviderUi.getUserName(
                                this.props.provider,
                                user
                              ),
                              client_id: user.client_id,
                              clientName: ProviderUi.getAudienceName(
                                this.props.provider,
                                user.client_id
                              ),
                            });
                          return resp;
                        })
                        .flat(1)
                        .map(token => (
                          <Dropdown.Item
                            key={token.token}
                            active={
                              this.props.request.token?.token === token.token
                            }
                            onClick={() => {
                              this.save(
                                Object.assign({}, this.props.request, {
                                  token,
                                })
                              );
                            }}
                          >
                            {`${
                              token.audienceName !== token.clientName
                                ? "With target " + token.audienceName
                                : "Standard refresh_token"
                            }`}
                          </Dropdown.Item>
                        ));

                      return children.length ? (
                        <React.Fragment key={user.sub + user.client_id}>
                          <Dropdown.Header>
                            {`${ProviderUi.getUserName(
                              this.props.provider,
                              user
                            )} ${
                              user.sub !== user.client_id
                                ? "on " +
                                  ProviderUi.getAudienceName(
                                    this.props.provider,
                                    user.client_id
                                  )
                                : ""
                            }`}
                          </Dropdown.Header>
                          <Dropdown.Divider />
                          {children}
                        </React.Fragment>
                      ) : (
                        ""
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              {Object.keys(this.props.provider.apis.list).length ?
                <Form.Field>
                  <label htmlFor="apis">API</label>
                  <Dropdown
                    id="apis"
                    options={Object.entries(this.props.provider.apis.list).map(
                      ([key, api]) => ({
                        key,
                        value: key,
                        text: ProviderUi.getAudienceName(
                          this.props.provider,
                          api.ref
                        ),
                      })
                    )}
                    placeholder="apis"
                    selection
                    clearable
                    fluid
                    value={(api && api[0]) || ""}
                    onChange={async (_, { value }: { value: string }) => {
                      const api = this.props.provider.apis.list[value];
                      if (!api) {
                        this.save(
                          Object.assign({}, this.props.request, {
                            audience: null,
                            resource: null,
                          })
                        );
                      } else if (api.type === ApiType.audience) {
                        this.save(
                          Object.assign({}, this.props.request, {
                            audience: api.ref,
                            resource: null,
                          })
                        );
                      } else if (api.type === ApiType.resource) {
                        this.save(
                          Object.assign({}, this.props.request, {
                            audience: null,
                            resource: api.ref,
                          })
                        );
                      }
                    }}
                  />
                </Form.Field>
              : ""}
            </Form.Group>
          </Form>
          <Button
            fluid
            color="green"
            onClick={async () => {
              this.setState({ loading: true });
              const response = await provider.refresh(
                this.props.request.token?.sub,
                this.props.request.token?.token,
                this.props.provider.clients.list[this.props.request.client_key],
                {
                  audience: this.props.request.audience,
                  resource: this.props.request.resource,
                }
              );
              this.save(this.props.request);
              this.setState({ response, loading: false });
            }}
            disabled={!this.props.request.token}
          >
            Run
          </Button>

          {this.state.response ? (
            <CodeBlock
              code={JSON.stringify(this.state.response, null, 2)}
              language="json"
            />
          ) : (
            ""
          )}
        </Card.Content>
      </Card>
    );
  }
};

export { Request, IRequestModel, empty };
