import React, { Component } from "react";
import {
  Dropdown,
  Form,
  Input,
  Button,
  Card,
  Dimmer,
  Loader,
} from "semantic-ui-react";

import * as ProviderUi from "../provider";
import * as RequestUi from "./index";

import Provider from "../../../tools/oauth2/provider";
import CodeBlock from "../../codeblock";

const empty = function(name: string): IRequestModel {
  return {
    type: RequestUi.RequestType.end_session,
    name: name,
    scopes: [] as string[],
    client_key: "",
    id_token: "",
    id_token_name: "",
  };
};

type IRequestModel = RequestUi.IBase & {
  type: RequestUi.RequestType.end_session;
  id_token: string;
  id_token_name: string;
};

type RequestProps = {
  id?: string;
  provider: ProviderUi.IModel;
  request: IRequestModel;
  onSave: (request: IRequestModel) => void;
};

const Request = class extends Component<
  RequestProps,
  { loading: boolean; response: any }
> {
  state = { loading: false, response: null };
  save(request: IRequestModel) {
    this.props.onSave({ ...request });
  }
  render() {
    const client = this.props.provider.clients?.list[
      this.props.request.client_key
    ];
    const provider = new Provider(this.props.provider.metadata);
    return (
      <Card fluid style={{ boxShadow: "none" }}>
        <Card.Content>
          <Dimmer active={this.state.loading}>
            <Loader>Loading</Loader>
          </Dimmer>
          <Form>
            <Form.Group widths="equal">
              <Form.Field>
                <label htmlFor="name">name</label>
                <Input
                  placeholder="name"
                  id="name"
                  onChange={e => {
                    this.save(
                      Object.assign({}, this.props.request, {
                        name: e.target.value,
                      })
                    );
                  }}
                  value={this.props.request.name}
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="Token">Token</label>
                <Dropdown
                  id="Token"
                  placeholder={this.props.request.id_token_name || "Token"}
                  value={this.props.request.id_token_name}
                  className="selection"
                  fluid
                >
                  <Dropdown.Menu>
                    {provider.users.map(user =>
                      user.sub === user.client_id ? (
                        ""
                      ) : (
                        <React.Fragment key={user.sub + user.client_id}>
                          <Dropdown.Header>
                            {`${ProviderUi.getUserName(
                              this.props.provider,
                              user
                            )}` +
                              " on " +
                              ProviderUi.getAudienceName(
                                this.props.provider,
                                user.client_id
                              )}
                          </Dropdown.Header>
                          <Dropdown.Divider />
                          {Object.entries(user.tokens)
                            .map(([audience, tokenSet]) => {
                              const resp = [];
                              if (tokenSet.id_token)
                                resp.push({
                                  id_token: tokenSet.id_token,
                                  client_key: audience,
                                });
                              return resp;
                            })
                            .flat(1)
                            .map(({ id_token, client_key }) => (
                              <Dropdown.Item
                                key={id_token}
                                active={
                                  this.props.request.id_token === id_token
                                }
                                onClick={() => {
                                  this.save(
                                    Object.assign({}, this.props.request, {
                                      id_token,
                                      id_token_name: ProviderUi.getUserName(
                                        this.props.provider,
                                        user
                                      ),
                                      client_key,
                                    })
                                  );
                                }}
                              >
                                id_token
                              </Dropdown.Item>
                            ))}
                        </React.Fragment>
                      )
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Field>
            </Form.Group>
          </Form>
          <Button
            fluid
            color="green"
            onClick={async () => {
              this.setState({ loading: true });
              const request = this.props.request;
              console.log({ request, provider });
              const redirect_uri =
                window.location.protocol +
                "//" +
                window.location.host +
                window.location.pathname;
              await provider.endSession(request.id_token, redirect_uri);
              this.setState({ loading: false });
            }}
          >
            Run
          </Button>
          {this.state.response ? (
            <CodeBlock
              code={JSON.stringify(this.state.response, null, 2)}
              language="json"
            />
          ) : (
            ""
          )}
        </Card.Content>
      </Card>
    );
  }
};

export { Request, IRequestModel, empty };
